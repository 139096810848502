<!-- <form [formGroup]="form" (submit)="register()">
    <div>Username</div>
    <div>
        <input nz-input placeholder="Username" formControlName="username" />
    </div>
    <div>Email</div>
    <div>
        <input type="email" nz-input placeholder="Email" formControlName="email" />
    </div>
    <div>Password</div>
    <div>
        <input type="password" nz-input placeholder="Password" formControlName="password" />
    </div>
    <div>Confirm password</div>
    <div>
        <input type="password" nz-input placeholder="Confirm password" formControlName="password2" />
    </div>

    <button nz-button nzType="primary" type="submit">Register</button>

</form> -->
<section class="page-top-section set-bg">
    <div class="page-info">
      <h2>Register</h2>
    </div>
  </section>
  <section class="contact-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 order-2 order-lg-1"></div>
        <div class="col-lg-6 order-2 order-lg-1">
          <h2 style="color: white;">Register</h2>
          <form [formGroup]="form" class="contact-form" (submit)="register()">
            <input type="email" formControlName="email" placeholder="Email address" required autofocus>
            <input  type="password"
            formControlName="password" placeholder="Password"
            required>
            <input  type="password"
            placeholder="Confirm password" formControlName="password2"
            required>
            <button type="submit" class="site-btn"> Register<img src="./assets/img/icons/double-arrow.png" alt="#" /></button>
          </form>
        </div>
  
      </div>
    </div>
  </section>