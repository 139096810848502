import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenericHttpService {

  constructor(
    private http: HttpClient,
  ) { }

  get(path): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${path}`, { headers: { 'Content-Type': 'application/json; charset=utf-8;', token: localStorage.getItem(`token`) == null ? "" : localStorage.getItem(`token`) } });
  }

  post(path, payload): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${path}`, JSON.stringify(payload), { headers: { 'Content-Type': 'application/json; charset=utf-8;', token: localStorage.getItem(`token`) == null ? "" : localStorage.getItem(`token`) } });
  }

  put(path, payload): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}${path}`, JSON.stringify(payload), { headers: { 'Content-Type': 'application/json; charset=utf-8;', token: localStorage.getItem(`token`) } });
  }

  delete(path): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.apiUrl}${path}`, { headers: { 'Content-Type': 'application/json; charset=utf-8;', token: localStorage.getItem(`token`) } });
  }

}
