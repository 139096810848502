<section class="page-top-section set-bg" data-setbg="img/page-top-bg/3.jpg">
  <div class="page-info">
    <h2>All articles</h2>
  </div>
</section>
<div nz-row nzJustify="space-around">
  <div nz-col nzSpan="20">
    <nz-table #newsTable [nzData]="news.list" nzSize="small">
      <thead>
        <tr>
          <th>Title</th>
          <th>Date</th>
          <th>Is published?</th>
          <th nzWidth="10%">
            <button
              nz-button
              nzType="primary"
              [routerLink]="['/blog/manage/article/create']"
            >
              New article
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of newsTable.data">
          <td>
            <a href="/blog/news/{{ data.titleUrl }}" target="_blank">{{ data.title }}</a>
          </td>
          <td>{{ data.createdOn }}</td>
          <td>
            <nz-tag *ngIf="data.isDeleted === true" [nzColor]="'orange'"
              >No</nz-tag
            >
            <nz-tag *ngIf="data.isDeleted === false" [nzColor]="'green'"
              >Yes</nz-tag
            >
          </td>
          <td>
            <nz-button-group>
              <button
                nz-button
                nzType="primary"
                [routerLink]="['/blog/manage/article', data.id, 'edit']"
              >
                Edit
              </button>
              <button
                nz-button
                *ngIf="data.isDeleted === false"
                nzType="primary"
                style="background-color: red"
                (click)="unpublish(data.id)"
              >
                Unpublish
              </button>
            </nz-button-group>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
