<!-- 
<div
  id="revo_main_wrapper"
  class="rev_slider_wrapper fullwidthbanner-container"
  data-alias="classic4export"
  style="
    margin: 0px auto;
    background-color: transparent;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  "
>

  <div
    id="rev_arrows"
    class="rev_slider fullwidthabanner"
    style="display: none"
    data-version="5.4.1"
  >
    <ul>
  
      <li
        data-index="rs-first"
        data-transition="fadeout"
        data-slotamount="default"
        data-hideafterloop="0"
        data-hideslideonmobile="off"
        data-easein="Power4.easeInOut"
        data-easeout="Power4.easeInOut"
        data-masterspeed="2000"
        data-thumb="../../../assets/images/arrow-slide1.jpg"
        data-rotate="0"
        data-fstransition="fade"
        data-fsmasterspeed="1500"
        data-fsslotamount="7"
        data-saveperformance="off"
      >
    
        <img
          src="../../../assets/images/slide1.jpg"
          alt="slide1"
          data-bgposition="center center"
          data-bgfit="cover"
          data-bgrepeat="no-repeat"
          data-bgparallax="10"
          class="rev-slidebg"
          data-no-retina
        />
       
        <div
          class="tp-caption tp-resizeme"
          data-x="['center','center','center','center']"
          data-hoffset="['0','0','0','0']"
          data-y="['middle','middle','middle','middle']"
          data-voffset="['-60','-60','-50','-50']"
          data-width="none"
          data-height="none"
          data-type="text"
          data-textAlign="['center','center','center','center']"
          data-responsive_offset="on"
          data-start="1000"
          data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
        >
          <h1 class="text-capitalize font-xlight whitecolor text-center">
            Your dream games
          </h1>
        </div>
       
        <div
          class="tp-caption tp-resizeme"
          data-x="['center','center','center','center']"
          data-hoffset="['0','0','0','0']"
          data-y="['middle','middle','middle','middle']"
          data-voffset="['0','0','0','0']"
          data-width="none"
          data-height="none"
          data-type="text"
          data-textAlign="['center','center','center','center']"
          data-responsive_offset="on"
          data-start="1500"
          data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
        >
          <h1 class="text-capitalize fontbold whitecolor text-center">
            Coming to life
          </h1>
        </div>
    
        <div
          class="tp-caption tp-resizeme"
          data-x="['center','center','center','center']"
          data-hoffset="['0','0','0','0']"
          data-y="['middle','middle','middle','middle']"
          data-voffset="['70','70','70','70']"
          data-width="none"
          data-height="none"
          data-whitespace="nowrap"
          data-type="text"
          data-textAlign="['center','center','center','center']"
          data-responsive_offset="on"
          data-start="2000"
          data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":2000,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
        >
          <h4 class="whitecolor font-light text-center">
            With everyone working together!
          </h4>
        </div>
      </li>
 
      <li
        data-index="rs-second"
        data-transition="fadetotopfadefrombottom"
        data-slotamount="default"
        data-hideafterloop="0"
        data-hideslideonmobile="off"
        data-easein="Power3.easeInOut"
        data-easeout="Power3.easeInOut"
        data-masterspeed="1500"
        data-thumb="images/arrow-slide2.jpg"
      >
       
        <img
          src="../../../assets/images/slide1.jpg"
          alt=""
          data-bgposition="center center"
          data-bgfit="cover"
          data-bgrepeat="no-repeat"
          data-bgparallax="10"
          class="rev-slidebg"
          data-no-retina
        />
     
        <div
          class="tp-caption tp-resizeme"
          data-x="['center','center','center','center']"
          data-hoffset="['0','0','0','0']"
          data-y="['middle','middle','middle','middle']"
          data-voffset="['-60','-60','-50','-50']"
          data-width="none"
          data-height="none"
          data-type="text"
          data-textAlign="['center','center','center','center']"
          data-responsive_offset="on"
          data-start="1000"
          data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
        >
          <h1 class="text-capitalize font-xlight whitecolor text-center">
            Free to play
          </h1>
        </div>
  
        <div
          class="tp-caption tp-resizeme"
          data-x="['center','center','center','center']"
          data-hoffset="['0','0','0','0']"
          data-y="['middle','middle','middle','middle']"
          data-voffset="['0','0','0','0']"
          data-width="none"
          data-height="none"
          data-type="text"
          data-textAlign="['center','center','center','center']"
          data-responsive_offset="on"
          data-start="1500"
          data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
        >
          <h1 class="text-capitalize fontbold whitecolor text-center">
            Free to win!
          </h1>
        </div>
      
        <div
          class="tp-caption tp-resizeme"
          data-x="['center','center','center','center']"
          data-hoffset="['0','0','0','0']"
          data-y="['middle','middle','middle','middle']"
          data-voffset="['70','70','70','70']"
          data-width="none"
          data-height="none"
          data-whitespace="nowrap"
          data-type="text"
          data-textAlign="['center','center','center','center']"
          data-responsive_offset="on"
          data-start="2000"
          data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":2000,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
        >
          <h4 class="whitecolor font-light text-center">
            All our games are community friendly and focus on fun and fairness
            to everyone!
          </h4>
        </div>
      </li>
    </ul>
  </div>
</div>
***Main Slider ends ***
*** Some Services ***
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div id="services-slider" class="owl-carousel">
        <div class="item">
          <div class="service-box">
            <span class="bottom25"><i class="fa fa-trophy"></i></span>
            <h4 class="bottom10"><a href="javascript:void(0)">vPopulus</a></h4>
            <p>
              Geopolitical game focused on strategy with real countries
              <br />Version: Release planned for Q4/2021 - Q1/2022
            </p>
          </div>
        </div>
        <div class="item">
          <div class="service-box">
            <span class="bottom25"><i class="fa fa-comments"></i></span>
            <h4 class="bottom10">
              <a href="javascript:void(0)">Rise of vPopulus</a>
            </h4>
            <p>
              Geopolitical game focused on strategy with fictional countries
              <br />Version: Release planned for Q3/2021.
            </p>
          </div>
        </div>
        <div class="item">
          <a href="https://simplan.io" target="_blank">
            <div class="service-box">
              <span class="bottom25"><i class="fa fa-ticket"></i></span>
              <h4 class="bottom10"><a href="javascript:void(0)">Simplan</a></h4>
              <p>Project manager we use for our projects.</p>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://i18n.div.pt" target="_blank">
            <div class="service-box">
              <span class="bottom25"><i class="fa fa-ticket"></i></span>
              <h4 class="bottom10"><a href="javascript:void(0)">DivI18N</a></h4>
              <p>Project created for translation of our projects.</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<section id="our-feature" class="padding single-feature">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 text-center">
        <div class="heading-title">
          <h2 class="darkcolor top15">Become partner</h2>
        </div>
        <p class="top15">
          We're doing this for over 10 years. We wish 10 years ago we had some
          guidance to help us through this challenging world of indie games.
          We're here for you, we'll help you and give you access to our tools
          and network.
        </p>
        <a [routerLink]="['/partners']" class="button btnprimary">Know more</a>
      </div>
    </div>
  </div>
</section>

*** Partners ends ***

<section id="our-blog" class="blog-parallax padding_top">
  <div class="container">
    <div class="heading-title heading_space text-center">
      <h2 class="darkcolor bottom30">Latest blog posts</h2>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12" *ngFor="let item of news">
        <div
          class="heading-title text-md-left text-center padding_bottom wow fadeInLeft"
          data-wow-delay="300ms"
        >
          <h3 class="fontregular bottom20 darkcolor">{{ item.title }}</h3>
          <a
            [routerLink]="['/blog/news', item.titleUrl]"
            class="button btnprimary fontmedium top20"
            >Read more</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
 -->
<!-- Hero section -->
<section class="hero-section">
  <div class="hero-slider">
    <div class="hero-item d-flex align-items-center justify-content-center text-center">
      <div class="container">
        <div class="row d-flex align-items-center justify-content-center text-center">
          <div class="col-md-10">
            <!--             <h3> <div id="countdown" class="countdown text-white mb-4" style="font-size: 2em;"></div></h3>
 -->
            <h2>Free to Play <br>
              Free to Win!</h2>
            <p> All our games are community friendly and focus on fun and fairness to everyone! </p>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- Hero section end-->


<!-- Intro section -->
<!-- <section class="intro-section" *ngIf="!isLoading">
  <div class="container">
    <div class="row">
      <div class="col-md-4" *ngFor="let item of news">
        <div class="intro-text-box text-box text-white">
          <div class="top-meta">{{ item.createdOn | date }} <a href="">{{ item.category }}</a></div>
          <h3>{{ item.title }}</h3>
          <p>{{ item.description | slice:0:200 }}...</p> 
          <a [href]="'/news/' + item.id" class="read-more">Read More <img src="./assets/img/icons/double-arrow.png" alt="#" /></a>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- Intro section end -->


<!-- Blog section -->
<section class="blog-section spad" *ngIf="!isLoading">
  <div class="container">
    <div class="row">
      <div class="col-xl-9 col-lg-9 col-md-7">
        <div class="section-title text-white">
          <h2>Latest News</h2>
        </div>
        <!--      <ul class="blog-filter">
          <li><a href="#">Racing</a></li>
          <li><a href="#">Shooters</a></li>
          <li><a href="#">Strategy</a></li>
          <li><a href="#">Online</a></li>
        </ul> -->
        <div class="blog-item" *ngFor="let item of news">
          <div class="blog-thumb">
            <img [src]="item.imageUrl || ('./assets/img/blog/' + generateImageIndex(item.id) + '.jpg')"
              alt="Imagem da notícia">
          </div>
          <div class="blog-text text-box text-white">
            <div class="top-meta">{{ item.createdOn | date }} <a href="">{{ item.category }}</a></div>
            <h3>{{ item.title }}</h3>
            <p>{{ item.description | slice:0:250 }}...</p>
            <a [href]="'blog/news/' + item.titleUrl" class="read-more">Read More <img
                src="./assets/img/icons/double-arrow.png" alt="#" /></a>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-5 sidebar">
        <!--  <div id="stickySidebar">
          <div class="widget-item">
            <h4 class="widget-title">Trending</h4>
            <div class="trending-widget">
              <div class="tw-item" *ngFor="let trend of trendingNews">
                <div class="tw-thumb">
                  <img [src]="trend.imageUrl" alt="#">
                </div>
                <div class="tw-text">
                  <div class="tw-meta">{{ trend.createdOn | date }} <a href="">{{ trend.category }}</a></div>
                  <h5>{{ trend.title }}</h5>
                </div>
              </div>
            </div>
          </div>
       <div class="widget-item">
            <div class="categories-widget">
              <h4 class="widget-title">Categories</h4>
              <ul>
                <li><a href="">Games</a></li>
                <li><a href="">Gaming Tips & Tricks</a></li>
                <li><a href="">Online Games</a></li>
                <li><a href="">Team Games</a></li>
                <li><a href="">Community</a></li>
                <li><a href="">Uncategorized</a></li>
              </ul>
            </div>
          </div>
          <div class="widget-item">
            <a href="#" class="add">
              <img src="./assets/img/add.jpg" alt="">
            </a>
          </div> 
        </div> -->
      </div>
    </div>
  </div>
</section>

<!-- Blog section end -->


<!-- Featured section -->
<section class="featured-section">
  <div class="featured-bg set-bg"><img style="height: 100% ; width:auto;
	float: left;" src="./assets/img/featured-bg.jpg" alt="#"></div>
  <div class="featured-box">
    <div class="text-box">
      <h3>The game you’ve been waiting for is comming soon</h3>
      <p>vPopulus is a social, strategic, massively multiplayer online browser game. It's a real-time model of the world
        where players can join political, economic, military and journalistic careers. Players or citzens can manage
        companies and sell products both on national and international markets, exchange different currencies for
        personal profit, become a stockmarket mogul, join political parties and candidate for congress or president. You
        can join the army and fight in a war against another country or you can be journalist and become a world known
        presence. vPopulus is a game that is heavily based on the social aspects of gaming. It’s not just a game, it’s a
        huge community of players from around the globe. The possibilities are endless!</p>
      <a [routerLink]="['/projects/vpopulus']" class="read-more">Read More <img src="./assets/img/icons/double-arrow.png" alt="#" /></a>
    </div>
  </div>
</section>
<!-- Featured section end-->



<!-- Newsletter section -->
<!-- <section class="newsletter-section">
  <div class="container">
    <h2>Subscribe to our newsletter</h2>
    <form class="newsletter-form">
      <input type="text" placeholder="ENTER YOUR E-MAIL">
      <button class="site-btn">subscribe <img src="./assets/img/icons/double-arrow.png" alt="#" /></button>
    </form>
  </div>
</section> -->
<!-- Newsletter section end -->