<!-- <section id="our-feature" class="padding single-feature">
  <div class="container">
    <div class="row" *ngFor="let item of news.list">
      <div
        class="col-md-9 col-sm-10 text-md-left text-center wow fadeInLeft"
        data-wow-delay="300ms"
      >
        <div class="heading-title heading_space">
          <span> Tags (<span [innerHtml]="item.tags"></span>) </span>
          <h2 class="darkcolor bottom30">{{ item.title }}</h2>
        </div>
        <p class="bottom35">{{ item.description }}</p>
        <a
          [routerLink]="['/blog/news', item.titleUrl]"
          class="button btnprimary"
          >Read more</a
        >
      </div>
    </div>
    <hr />
    <div style="text-align: center">
      <nz-pagination
        [nzPageIndex]="page"
        [nzPageSize]="pageSize"
        [nzTotal]="news.totalEntries"
        (nzPageIndexChange)="pageIndexChange($event)"
        (nzPageSizeChange)="pageSizeChange($event)"
      ></nz-pagination>
    </div>
  </div>
</section>
 -->
 <section class="page-top-section set-bg">
  <div class="page-info">
    <h2>Blog</h2>
  </div>
</section>
 <section class="review-section">
  <div class="container">
    <div class="review-item" *ngFor="let item of news.list">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="review-pic">
            <img [src]="item.imageUrl || ('./assets/img/blog/' + generateImageIndex(item.id) + '.jpg')" alt="Imagem da notícia">
          </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12">
          <div class="review-content text-box text-white">
            <div class="top-meta">{{ item.createdOn | date }}</div>
            <div class="heading-title heading_space">
              <span> Tags (<span [innerHtml]="item.tags"></span>) </span>
              <h2 class="darkcolor bottom30">{{ item.title }}</h2>
            </div>
            <p class="bottom35">{{ item.description }}</p>
            <a [routerLink]="['/blog/news', item.titleUrl]" class="read-more">
              Read More  <img src="./assets/img/icons/double-arrow.png" alt="#">
            </a>
          </div>
        </div>
      </div>
      
    </div>
    
    <div class="site-pagination">
      <a *ngFor="let pageNumber of generatePages()" 
         (click)="changePage(pageNumber)" 
         [class.active]="page === pageNumber">{{ pageNumber < 10 ? '0' + pageNumber : pageNumber }}.</a>
    </div>
  </div>
</section>

<!-- <section class="newsletter-section">
  <div class="container">
    <h2>Subscribe to our newsletter</h2>
    <form class="newsletter-form">
      <input type="text" placeholder="ENTER YOUR E-MAIL">
      <button class="site-btn">subscribe  <img src="img/icons/double-arrow.png" alt="#"></button>
    </form>
  </div>
</section> -->