import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GenericHttpService } from 'src/app/generic-http-service';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.less'],
})
export class OauthComponent implements OnInit {
  applicationNotRegistered = false;

  isLoading;

  form: FormGroup;

  application;
  query;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: GenericHttpService,
    private router: Router,
    private messageService: NzMessageService
  ) {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((query) => {
      this.query = query;
      this.httpService
        .get(
          `/api/oauth/verify?clientId=${query.client_id}&redirectUri=${query.redirect_uri}`
        )
        .subscribe(
          (r) => {
            this.application = r;
            this.form.get(`state`).setValue(query.state);

            if (localStorage.getItem(`token`)) {
              this.httpService
                .get(`/api/oauth/token-verify?token=${localStorage.getItem(`token`)}&state=${this.query.state}`)
                .subscribe(
                  (a) => {
                    window.location.href = `${this.query.redirect_uri}?code=${a.code}&state=${this.query.state}`;
                  });
            }
          },
          (e) => {
            this.applicationNotRegistered = true;
          }
        );
    });
  }

  login() {
    this.isLoading = true;
    this.httpService
      .post(`/api/oauth/login`, this.form.getRawValue())
      .subscribe(
        (r) => {
          localStorage.setItem(`token`, r.accessToken);
          window.location.href = `${this.query.redirect_uri}?code=${r.code}&state=${this.query.state}`;
        },
        (error) => {
          if (error.error.Message === 'ACCOUNT_NOT_FOUND')
            this.messageService.error('Account not found!');
          if (error.error.Message === 'WRONG_PASSWORD')
            this.messageService.error('Wrong password!');
          if (error.error.Message === 'ACCOUNT_DISABLED')
            this.messageService.error('Account not activated!');
        }
      );
  }
}
