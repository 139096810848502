import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  filesLoaded = false;
  jsFiles = [
    "../../assets/js/jquery-3.2.1.min.js",
    "../../assets/js/bootstrap.min.js",
"../../assets/js/jquery.slicknav.min.js"
  ];
  jsFiles2 = [
"../../assets/js/jquery.sticky-sidebar.min.js",
"../../assets/js/main.js"
  ];

visible = false;
 ngOnInit(): void {
    this.jsFiles.forEach(file => this.importJsFile(file));
    this.filesLoaded = true;
 }
open(): void {
  this.visible = true;
}
ngAfterViewInit() {
  setTimeout(() => {
    this.jsFiles2.forEach(file => this.importJsFile(file));
  }, 2000);
}
importJsFile = (path) => {
  let node = document.createElement('script');
  node.src = path;
  node.type = 'text/javascript';
  node.async = true;
  node.charset = 'utf-8';
  document.getElementsByTagName('head')[0].appendChild(node);
}
close(): void {
  this.visible = false;
}
scroll(id: string) {
  let el = document.getElementById(id);
  console.log(id);
  el!.scrollIntoView();
}
onActivate(event) {
  // window.scroll(0,0);

  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });

}
}