<section id="careers" class="padding bglight">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2 col-sm-12 text-center">
                <div class="heading-title">
                    <span>Work with us!</span>
                    <h2 class="darkcolor bottom30">Jobs</h2>
                    <p>Working for a small indie studio can have its perks! Our positions can range from Non-paid
                        internship, to Erasmus+ intership, freelancer or even recieve a % of the profits!</p>
                    <p><b>All positions are eligible for Erasmus+ internship at our offices in Portugal!</b></p>
                </div>
            </div>
        </div>
        <div class="row three-col-pricing">
            <div class="col-lg-4 col-sm-12">
                <div class="price-table top60">
                    <h3 class="bottom20 darkcolor">Backend Developer</h3>
                    <ul class="top20">
                        <li><span>C# / .net core 2.1+</span></li>
                        <li><span>SQL - MySql/Postgres</span></li>
                        <li><span>DDD + DI knowledge</span></li>
                        <li><span>EntityFramework</span></li>
                        <li><span>Linq</span></li>
                        <li><span>AutoMapper</span></li>
                    </ul>
                    <div class="clearfix"></div>
                    <a href="mailto:careers@pastalabs.eu" class="button btnprimary top25">Get Started </a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12">
                <div class="price-table active top60">
                    <h3 class="bottom20 darkcolor">Frontend Developer</h3>
                    <ul class="top20">
                        <li><span>Angular 6+</span></li>
                        <li><span>Typescript</span></li>
                        <li><span>Ant design zorro</span></li>
                        <li><span>Basics of HTML and CSS</span></li>
                    </ul>
                    <div class="clearfix"></div>
                    <a href="mailto:careers@pastalabs.eu" class="button btnprimary top25">Get Started </a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12">
                <div class="price-table top60">
                    <h3 class="bottom20 darkcolor">What we offer</h3>
                    <p>If you are taking your first steps and looking for guidence or an experienced developer looking
                        for a new project, this is the right place!</p>
                    <ul class="top20">
                        <li><span>Integration in an young company</span></li>
                        <li><span>Remote work</span></li>
                        <li><span>Continuous guidance</span></li>
                        <li><span>Free office space with all the commodities (Portuguese office only)</span></li>
                        <li><span>Access to our freelancer jobs</span></li>
                        <li><span>Pay depending the position and experience</span></li>
                        <li><span>Free Spotify premium</span></li>
                    </ul>
                    <div class="clearfix"></div>
                    <a href="mailto:careers@pastalabs.eu" class="button btnprimary top25">Get Started </a>
                </div>
            </div>
        </div>
    </div>
</section>