import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rise-of-vpopulus',
  templateUrl: './rise-of-vpopulus.component.html',
  styleUrls: ['./rise-of-vpopulus.component.less']
})
export class RiseOfVpopulusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
