import { Component, OnInit } from '@angular/core';
import { GenericHttpService } from 'src/app/generic-http-service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  isLoading;

  form: FormGroup;

  constructor(
    private httpService: GenericHttpService,
    private messageService: NzMessageService,
    private router: Router
  ) {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required])
    })
  }

  ngOnInit(): void {

  }

  login() {
    this.isLoading = true;
    this.httpService.post(`/api/auth/login`, this.form.getRawValue()).subscribe(r => {
      this.messageService.success("Logged in!");
      localStorage.setItem(`token`, r);
      this.isLoading = false;
      window.location.href = '/account'
      // this.router.navigateByUrl(`/account`);
    }, error => {
      if (error.error.Message === "ACCOUNT_NOT_FOUND") this.messageService.error("Account not found!");
      if (error.error.Message === "WRONG_PASSWORD") this.messageService.error("Wrong password!");
      if (error.error.Message === "ACCOUNT_DISABLED") this.messageService.error("Account not activated!");
    })
  }

}
