import { Component, OnInit } from '@angular/core';
import { GenericHttpService } from 'src/app/generic-http-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article-edit.component.less']
})
export class ArticleEditComponent implements OnInit {

  tags = [];

  article = {
    id: undefined,
    title: '',
    tags: [],
    description: ''
  }

  constructor(
    private httpService: GenericHttpService,
    private route: ActivatedRoute,
    private messageService: NzMessageService, 
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadTags();
    this.route.params.subscribe(r => {
      if (r['id'] === undefined) return;
      this.httpService.get(`/api/blog/news/by/id/${r['id']}`).subscribe(r => {
        this.article.id = r.id;
        this.article.title = r.title;
        this.article.description = r.description;
        this.httpService.get(`/api/blog/news/${r.id}/tags`).subscribe(tags => {
          this.article.tags = tags;
        })
      })
    })
  }

  loadTags() {
    this.httpService.get(`/api/blog/news/tags`).subscribe(r => {
      this.tags = r;
    })
  }

  publish() {
    if (this.article.id === undefined) {
      this.httpService.post(`/api/blog/news`, { title: this.article.title, description: this.article.description }).subscribe((r) => {
        this.httpService.put(`/api/blog/news/tags`, { newsId: r, tags: this.article.tags }).subscribe(() => {
          this.messageService.success("Article published!");
          this.loadTags();
          this.router.navigateByUrl(`/blog/manage/article/${r}/edit`)
        })
      })
    } else {
      this.httpService.put(`/api/blog/news`, { id: this.article.id, title: this.article.title, description: this.article.description }).subscribe(() => {
        this.httpService.put(`/api/blog/news/tags`, { newsId: this.article.id, tags: this.article.tags }).subscribe(() => {
          this.messageService.success("Article updated!");
          this.loadTags();
        })
      })
    }
  }

}
