import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './views/about/about.component';
import { HomeComponent } from './views/home/home.component';
import { VpopulusComponent } from './views/games/vpopulus/vpopulus.component';
import { RiseOfVpopulusComponent } from './views/games/rise-of-vpopulus/rise-of-vpopulus.component';
import { RiseOfSocietyComponent } from './views/games/rise-of-society/rise-of-society.component';
import { SimplanComponent } from './views/projects/simplan/simplan.component';
import { CarrersComponent } from './views/carrers/carrers.component';
import { PartnersComponent } from './views/partners/partners.component';
import { RecoverPasswordComponent } from './views/auth/recover-password/recover-password.component';
import { LoginComponent } from './views/auth/login/login.component';
import { RegisterComponent } from './views/auth/register/register.component';
import { I18nComponent } from './views/projects/i18n/i18n.component';
import { BlogListComponent } from './views/blog/blog-list/blog-list.component';
import { BlogPostComponent } from './views/blog/blog-post/blog-post.component';
import { TagsListComponent } from './views/blog/tags-list/tags-list.component';
import { ResetPasswordComponent } from './views/auth/reset-password/reset-password.component';
import { ArticleListComponent } from './views/blog/backoffice/article-list/article-list.component';
import { ArticleEditComponent } from './views/blog/backoffice/article-edit/article-edit.component';
import { ManageComponent } from './views/auth/manage/manage.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_GB } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FooterComponent } from './views/layout/footer/footer.component';
import { HeaderComponent } from './views/layout/header/header.component';
import { OauthComponent } from './views/auth/oauth/oauth.component';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzInputModule } from 'ng-zorro-antd/input';


registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HomeComponent,
    VpopulusComponent,
    RiseOfVpopulusComponent,
    RiseOfSocietyComponent,
    SimplanComponent,
    CarrersComponent,
    PartnersComponent,
    LoginComponent,
    RegisterComponent,
    RecoverPasswordComponent,
    I18nComponent,
    BlogListComponent,
    BlogPostComponent,
    TagsListComponent,
    ResetPasswordComponent,
    ArticleListComponent,
    ArticleEditComponent,
    ManageComponent,
    FooterComponent,
    HeaderComponent,
    OauthComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NzMessageModule,
    NzNotificationModule,
    NzPaginationModule,
    NzButtonModule,
    NzTagModule,
    NzTableModule,
    NzSelectModule,
    NzCardModule,
    NzSpinModule,
    NzAlertModule,
    NzInputModule,
  ],
  providers: [{ provide: NZ_I18N, useValue: en_GB }],
  bootstrap: [AppComponent]
})
export class AppModule { }
