<!-- <section id="our-team" class="padding bglight">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2 col-sm-12 text-center">
        <div class="heading-title wow fadeInUp" data-wow-delay="300ms">
          <span>Welcome to Pasta labs </span>
          <h2 class="darkcolor bottom20">About us</h2>
          <p class="bottom10">
            Pasta labs is an indendent web-browser games developer. The studio
            started in 2011 (as vPopulus Studio) and currently has offices in
            Portugal and Poland. All our games are free to play and we make an
            extra effort to ensure that our projects are as fun and close to
            reality as possible. Gameplay and feedback from our players is what
            we value the most.
            <br />
            The Studio also has a great remote work policy and offers guidance
            for new programmers looking for experience.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div
          class="team-box no-hover top60 wow fadeInUp"
          data-wow-delay="350ms"
          style="
            visibility: visible;
            animation-delay: 350ms;
            animation-name: fadeInUp;
          "
        >
          <div class="image">
            <img src="../../../assets/images/vasco.jpg" alt="Vasco Lebre" />
          </div>
          <div class="team-content bgpasta">
            <h3>Vasco Lebre (Klavh)</h3>
            <p class="bottom40">Co-Founder / Community manager</p>
            <div class="progress-bars">
              <div class="side-footer w-100">
                <ul class="social-icons-simple white"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div
          class="team-box no-hover top60 wow fadeInUp"
          data-wow-delay="350ms"
          style="
            visibility: visible;
            animation-delay: 350ms;
            animation-name: fadeInUp;
          "
        >
          <div class="image">
            <img src="../../../assets/images/dano.jpg" alt="Daniel Rudziński" />
          </div>
          <div class="team-content gradient_bg_default">
            <h3>Daniel Rudziński (Dano19)</h3>
            <p class="bottom40">Co-Founder / Full-stack developer</p>
            <div class="progress-bars">
              <div class="side-footer w-100">
                <ul class="social-icons-simple white">
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div
          class="team-box no-hover top60 wow fadeInUp"
          data-wow-delay="350ms"
          style="
            visibility: visible;
            animation-delay: 350ms;
            animation-name: fadeInUp;
          "
        >
          <div class="image">
            <img src="../../../assets/images/anna.jpg" alt="Anna Englert" />
          </div>
          <div class="team-content bgpasta">
            <h3>Anna Englert (Shantka)</h3>
            <p class="bottom40">Jr. Community manager</p>
            <div class="progress-bars">
              <div class="side-footer w-100">
                <ul class="social-icons-simple white"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div
          class="team-box no-hover top60 wow fadeInUp"
          data-wow-delay="350ms"
          style="
            visibility: visible;
            animation-delay: 350ms;
            animation-name: fadeInUp;
          "
        >
          <div class="image">
            <img src="../../../assets/images/marek.jpg" alt="Marek Timm" />
          </div>
          <div class="team-content bgpasta">
            <h3>Marek Timm (Timek)</h3>
            <p class="bottom40">Jr. frontend developer</p>
            <div class="progress-bars">
              <div class="side-footer w-100">
                <ul class="social-icons-simple white"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
 -->
<section class="page-top-section set-bg">
</section>
<section class="games-single-page">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 game-single-content">
        <h2 class="gs-title">Welcome to Pasta labs</h2>
        <p>
          Pasta labs is an indendent web-browser games developer. The studio
          started in 2011 (as vPopulus Studio) and currently has offices in
          Portugal and Poland. All our games are free to play and we make an
          extra effort to ensure that our projects are as fun and close to
          reality as possible. Gameplay and feedback from our players is what
          we value the most.
          <br />
          The Studio also has a great remote work policy and offers guidance
          for new programmers looking for experience.
        </p>
        <div class="geme-social-share pt-5 d-flex">
          <p>Share:</p>
          <!--                     <a href="#"><i class="fa fa-pinterest"></i></a> -->
          <a href="#"><i class="fa fa-facebook"></i></a>
          <a href="#"><i class="fa fa-twitter"></i></a>
          <a href="#"><i class="fa fa-instagram"></i></a>
          <!-- <a href="#"><i class="fa fa-behance"></i></a>  -->
        </div>
      </div>
    </div>
  </div>
</section>