<!-- <section id="our-feature" class="padding single-feature">
  <div class="container">
    <h3>News by tag: {{ tag }}</h3>
    <hr />
    <div class="row" *ngFor="let item of news">
      <div
        class="col-md-9 col-sm-10 text-md-left text-center wow fadeInLeft"
        data-wow-delay="300ms"
      >
        <div class="heading-title heading_space">
          <h2 class="darkcolor bottom30">{{ item.title }}</h2>
        </div>
        <p class="bottom35">{{ item.description }}</p>
        <a
          [routerLink]="['/blog/news', item.titleUrl]"
          class="button btnprimary"
          >Read more</a
        >
      </div>
    </div>
  </div>
</section>
 -->
<section class="page-top-section set-bg">
  <div class="page-info">
    <h2>News by tag: {{ tag }}</h2>
  </div>
</section>
 <section class="review-section">
  <div class="container">
    <div class="review-item" *ngFor="let item of news">
      <div class="row">
        <div class="col-lg-4">
          <div class="review-pic">
            <img  [src]="item.imageUrl || ('./assets/img/blog/' + generateImageIndex(item.id) + '.jpg')" alt="Imagem da notícia">
          </div>
        </div>
        <div class="col-lg-8">
          <div class="review-content text-box text-white">
            <div class="top-meta">{{ item.createdOn | date }}</div>
            <div class="heading-title heading_space">
              <h2 class="darkcolor bottom30">{{ item.title }}</h2>
            </div>
            <p class="bottom35">{{ item.description }}</p>
            <a [routerLink]="['/blog/news', item.titleUrl]" class="read-more">
              Read More  <img src="./assets/img/icons/double-arrow.png" alt="#">
            </a>
          </div>
        </div>
      </div>
    </div>
    

  </div>
</section>

