import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { GenericHttpService } from 'src/app/generic-http-service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.less'],
})
export class RecoverPasswordComponent implements OnInit {
  isLoading = false;

  email;

  constructor(
    private httpService: GenericHttpService,
    private notificationService: NzNotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  request() {
    if (!this.email) {
      return;
    }
    this.isLoading = true;

    this.httpService
      .post(`/api/auth/account/recover`, { email: this.email })
      .subscribe((r) => {
        this.notificationService.success(
          `Its done!`,
          `Password reset email has been sent! Please follow instructions provided in email.`,
          { nzDuration: 10000 }
        );
        this.isLoading = false;
        this.router.navigateByUrl(`/`);
      });
  }
}
