import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GenericHttpService } from 'src/app/generic-http-service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less'],
})
export class ResetPasswordComponent implements OnInit {
  isLoading;

  code;

  password = '';
  confirmPassword;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: GenericHttpService,
    private messageService: NzMessageService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.code = params['code'];
    });
  }

  reset() {
    if (!this.password || !this.confirmPassword) {
      return;
    }

    this.isLoading = true;
    this.httpService
      .post(`/api/auth/account/reset/password`, {
        code: 'asd',
        password: this.password,
        password2: this.confirmPassword,
      })
      .subscribe(
        () => {
          this.isLoading = false;
          this.messageService.success(
            `Password has been set! You can now log in!`
          );
          this.password = '';
          this.confirmPassword = '';
          this.code = '';
          this.router.navigateByUrl(`/`);
        },
        (e) => {
          if (e.error.Message === 'INVALID_CODE')
            this.messageService.error(`Invalid password reset code!`);
          if (e.error.Message === 'PASSWORD_LESS_THAN_4_OR_MORE_THAN_32')
            this.messageService.error(
              `Password needs to be between 4 and 32 characters!`
            );
          if (e.error.Message === 'PASSWORDS_DONT_MATCH')
            this.messageService.error(`Passwords didn't match!`);
          this.isLoading = false;
        }
      );
  }
}
