import { Component, OnInit } from '@angular/core';
import { GenericHttpService } from 'src/app/generic-http-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.less'],
})
export class TagsListComponent implements OnInit {
  tag;
  news = [];

  constructor(
    private httpService: GenericHttpService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.tag = params['tag'];
      this.httpService
        .get(`/api/blog/news/by/tag/${this.tag}`)
        .subscribe((news) => {
          this.news = news;
          this.news.forEach((news) => {
            news.description = news.description.substring(0, 350);
            news.titleUrl = encodeURI(news.title);
            this.httpService;
          });
        });
    });
  }
  generateImageIndex(id: any): number {
    const stringId = JSON.stringify(id);
    let hash = 0;
    for (let i = 0; i < stringId.length; i++) {
      hash = stringId.charCodeAt(i) + ((hash << 5) - hash);
    }
    return (Math.abs(hash) % 5) + 1;
  }
}
