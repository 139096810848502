import { Component, OnInit } from '@angular/core';
import { GenericHttpService } from 'src/app/generic-http-service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.less'],
})
export class BlogListComponent implements OnInit {
  isLoading = true;
  news = { list: [], totalEntries: 0 };
  page = 1;
  pageSize = 5;
  totalPages = 0;

  constructor(private httpService: GenericHttpService) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.httpService
      .get(`/api/blog/news/active?page=${this.page}&pageSize=${this.pageSize}`)
      .subscribe((r) => {
        this.news = r;
        this.totalPages = Math.ceil(r.totalEntries / this.pageSize);
        this.news.list.forEach((news) => {
          news.description = news.description.substring(0, 350);
          news.titleUrl = encodeURI(news.title);
          this.httpService
            .get(`/api/blog/news/${news.id}/tags`)
            .subscribe((tags) => {
              news['tags'] = tags
                .map((x) => `<a href='/blog/tags/${x}'>${x}</a>`)
                .join(`, `);
            });
        });
        this.isLoading = false;
      });
  }

  changePage(pageNumber: number) {
    this.page = pageNumber;
    this.loadData();
  }

  generatePages() {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }
  generateImageIndex(id: any): number {
    const stringId = JSON.stringify(id);
    let hash = 0;
    for (let i = 0; i < stringId.length; i++) {
      hash = stringId.charCodeAt(i) + ((hash << 5) - hash);
    }
    return (Math.abs(hash) % 5) + 1;
  }
  
}