import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GenericHttpService } from 'src/app/generic-http-service';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less']
})
export class RegisterComponent implements OnInit {

  isLoading;

  form: FormGroup;

  constructor(
    private httpService: GenericHttpService,
    private messageService: NzMessageService,
    private router: Router
  ) {
    this.form = new FormGroup({
      username: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      password2: new FormControl(null, [Validators.required])
    })
  }

  ngOnInit(): void {

  }

  register() {
    this.isLoading = true;
    this.httpService.post(`/api/auth/register`, this.form.getRawValue()).subscribe(r => {
      this.messageService.success(`Account has been registered! Please check your email for activation link!`)
      this.router.navigateByUrl(`/`);
      this.isLoading = false;
    }, error => {
      if (error.error.Message === "USERNAME_LESS_THAN_4_OR_MORE_THAN_16 ") this.messageService.error("Username needs to be between 4 and 16 characters!");
      if (error.error.Message === "PASSWORD_LESS_THAN_4_OR_MORE_THAN_32") this.messageService.error("Password needs to be between 4 and 16 characters!");
      if (error.error.Message === "PASSWORDS_DONT_MATCH") this.messageService.error("Passwords didn't matched!");
      if (error.error.Message === "USERNAME_IN_USE") this.messageService.error("Username already taken!");
      if (error.error.Message === "EMAIL_IN_USE") this.messageService.error("Email already taken!");
    })
  }

}
