<!-- <div style="margin: 50px; text-align: center">
  <nz-alert
    nzType="error"
    [nzMessage]="noAppReg"
    *ngIf="applicationNotRegistered === true"
  >
    <ng-template #noAppReg>
      <h4>
        Application is not registered! <br />
        Please contact developer of the application.
      </h4>
    </ng-template>
  </nz-alert>
</div>

<div class="container" *ngIf="applicationNotRegistered === false">
  <div class="row">
    <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <h5 class="card-title text-center">
            You are about to grant access to your data to service:<br />
            <i>{{ application?.applicationName }}</i
            >.
          </h5>
          <form [formGroup]="form" (submit)="login()">
            <div class="form-label-group">
              <input
                type="email"
                formControlName="email"
                id="inputEmail"
                class="form-control"
                placeholder="Email address"
                required
                autofocus
              />
              <label for="inputEmail">Email address</label>
            </div>

            <div class="form-label-group">
              <input
                type="password"
                formControlName="password"
                id="inputPassword"
                class="form-control"
                placeholder="Password"
                required
              />
              <label for="inputPassword">Password</label>
            </div>

            <button
              class="btn btn-lg btn-primary bgpasta btn-block text-uppercase"
              type="submit"
            >
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->
<section class="page-top-section set-bg">
  <div class="page-info">
    <h2>Login</h2>
  </div>
</section>
<nz-alert
nzType="error"
[nzMessage]="noAppReg"
*ngIf="applicationNotRegistered === true"
>
<ng-template #noAppReg>
  <h4>
    Application is not registered! <br />
    Please contact developer of the application.
  </h4>
</ng-template>
</nz-alert>
<section class="contact-page">
  <div class="container"  *ngIf="applicationNotRegistered === false">
    <div class="row">
      <div class="col-lg-3 order-2 order-lg-1"></div>
      <div class="col-lg-6 order-2 order-lg-1">
        <h5 class="card-title text-center">
          You are about to grant access to your data to service:<br />
          <i>{{ application?.applicationName }}</i
          >.
        </h5>
        <form [formGroup]="form" class="contact-form" (submit)="login()">
          <input type="email" formControlName="email" id="inputEmail" placeholder="Email address" required autofocus>
          <input  type="password"
          formControlName="password"
          id="inputPassword"   placeholder="Password"
          required>
        <div style="text-align: center;">
        <p>
          <a [routerLink]="['/password/recover']">Forgot your password?</a>
        </p></div>
          <button type="submit" class="site-btn"> Continue<img src="./assets/img/icons/double-arrow.png" alt="#" /></button>
        </form>
        <br>
        <hr>
        <div style="text-align: center;">
        <p>
          Don't have an account yet?
          <a [routerLink]="['/register']">Register here</a>.
        </p>
        </div>
      </div>

    </div>
  </div>
</section>