import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { AboutComponent } from './views/about/about.component';
import { CarrersComponent } from './views/carrers/carrers.component';
import { PartnersComponent } from './views/partners/partners.component';
import { LoginComponent } from './views/auth/login/login.component';
import { RegisterComponent } from './views/auth/register/register.component';
import { RecoverPasswordComponent } from './views/auth/recover-password/recover-password.component';
import { VpopulusComponent } from './views/games/vpopulus/vpopulus.component';
import { RiseOfSocietyComponent } from './views/games/rise-of-society/rise-of-society.component';
import { RiseOfVpopulusComponent } from './views/games/rise-of-vpopulus/rise-of-vpopulus.component';
import { SimplanComponent } from './views/projects/simplan/simplan.component';
import { RedPandaComponent } from './views/projects/red-panda/red-panda.component';
import { BlogListComponent } from './views/blog/blog-list/blog-list.component';
import { BlogPostComponent } from './views/blog/blog-post/blog-post.component';
import { TagsListComponent } from './views/blog/tags-list/tags-list.component';
import { ManageComponent } from './views/auth/manage/manage.component';
import { ArticleEditComponent } from './views/blog/backoffice/article-edit/article-edit.component';
import { ArticleListComponent } from './views/blog/backoffice/article-list/article-list.component';
import { ResetPasswordComponent } from './views/auth/reset-password/reset-password.component';
import { OauthComponent } from './views/auth/oauth/oauth.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },

  { path: 'about', component: AboutComponent },
  { path: 'careers', component: CarrersComponent },
  { path: 'partners', component: PartnersComponent },

  { path: 'blog', component: BlogListComponent },
  { path: 'blog/news/:title', component: BlogPostComponent },
  { path: 'blog/tags/:tag', component: TagsListComponent },

  { path: 'blog/manage', component: ArticleListComponent },
  { path: 'blog/manage/article/:id/edit', component: ArticleEditComponent },
  { path: 'blog/manage/article/create', component: ArticleEditComponent },

  { path: 'projects/vpopulus', component: VpopulusComponent },
  { path: 'projects/rise-of-vpopulus', component: RiseOfVpopulusComponent },
  { path: 'projects/rise-of-society', component: RiseOfSocietyComponent },
  { path: 'projects/simplan', component: SimplanComponent },
  { path: 'projects/red-panda', component: RedPandaComponent },

  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'password/recover', component: RecoverPasswordComponent },
  { path: 'password/reset/:code', component: ResetPasswordComponent },
  { path: 'account', component: ManageComponent },

  { path: 'oauth', component: OauthComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
