<!-- <div class="container">
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <nz-spin [nzSpinning]="isLoading">
            <h5 class="card-title text-center">
              <p>
                Resetting password. <br /><small
                  >Provide your new password and confirm it.</small
                >
              </p>
            </h5>
            <form class="form-signin">
              <div class="form-label-group">
                <input
                  type="text"
                  name="code"
                  id="inputEmail"
                  disabled
                  class="form-control"
                  required
                  placeholder="Email address"
                  autofocus
                  value="{{ code }}"
                />
                <label for="inputEmail">Code</label>
              </div>

              <div class="form-label-group">
                <input
                  type="password"
                  name="password"
                  [(ngModel)]="password"
                  id="inputPassword"
                  class="form-control"
                  placeholder="Password"
                  required
                />
                <label for="inputPassword">Password</label>
              </div>

              <div class="form-label-group">
                <input
                  type="password"
                  name="passwordConfirm"
                  [(ngModel)]="confirmPassword"
                  id="inputPassword"
                  class="form-control"
                  placeholder="Password"
                  required
                />
                <label for="inputPassword">Confirm password</label>
              </div>

              <button
                class="btn btn-lg btn-primary btn-block text-uppercase"
                type="button"
                (click)="reset()"
              >
                Set new password
              </button>
            </form>
          </nz-spin>
        </div>
      </div>
    </div>
  </div>
</div> -->

<section class="page-top-section set-bg">
  <div class="page-info">
    <h2>Reset password</h2>
  </div>
</section>
<section class="contact-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 order-2 order-lg-1"></div>
      <div class="col-lg-6 order-2 order-lg-1">
        <h2 style="color: white; text-align: center;">Resetting password</h2>
        <nz-spin [nzSpinning]="isLoading">
          <h5 class="card-title text-center">
            <p>
             <small style="color: white;"
                >Provide your new password and confirm it</small
              >
            </p>
          </h5>
          <div class="contact-form">
            <input
            type="text"
            name="code"
            id="inputEmail"
            disabled
            class="form-control"
            required
            placeholder="Email address"
            autofocus
            value="{{ code }}"
          />
            <input
            type="password"
            name="password"
            [(ngModel)]="password"
            id="inputPassword"
            class="form-control"
            placeholder="Password"
            required
          />
            <input
            type="password"
            name="passwordConfirm"
            [(ngModel)]="confirmPassword"
            id="inputPassword"
            class="form-control"
            placeholder="Password"
            required
          />
          </div>

          <button
          class="site-btn"            type="button"
          (click)="reset()"
          >
          Set new password
          </button>
        </nz-spin>
      </div>

    </div>
  </div>
</section>