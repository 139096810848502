import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
  isLoggedIn = false;
  isMenuOpen = false; 
  
  constructor() {}

  ngOnInit(): void {
    if (localStorage.getItem(`token`)) this.isLoggedIn = true;
    else this.isLoggedIn = false;
  }

  logout() {
    localStorage.clear();
    window.location.href = '/';
  }
}
