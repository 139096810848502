<section class="page-top-section set-bg">
  <div class="page-info">
    <h2>        <span *ngIf="article.id === undefined">Create article</span>
      <span *ngIf="article.id !== undefined">Edit article</span></h2>
  </div>
</section>
<section class="contact-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 order-2 order-lg-1">
        <div class="contact-form"  nzSize="small">
          <div>
            <input
              nz-input
              placeholder="Title"
              [(ngModel)]="article.title"
              style="width: 100%"
            />
          </div>
          <hr />
          <div>
            <nz-select
            nzMode="tags"
            style="width: 100%"
            nzPlaceHolder="Tag Mode"
            [(ngModel)]="article.tags"
          >
            <nz-option
              *ngFor="let option of tags"
              [nzLabel]="option"
              [nzValue]="option"
            >
            </nz-option>
          </nz-select>
          
          </div>
          <hr />
          <div>
            <textarea
            rows="8"
            [(ngModel)]="article.description"
            placeholder="Description"
            style="width: 100%"
            ></textarea>
          </div>
          <hr />
          <button nz-button class="site-btn" style="padding: 24px 30px;" (click)="publish()">Publish</button>
          <button
            nz-button
            class="default-btn"
            [routerLink]="['/blog/manage']"
            style="margin-left: 30px"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
