<!-- <div class="loader">
  <div class="loader-inner">
    <div class="loader-blocks">
      <span class="block-1"></span>
      <span class="block-2"></span>
      <span class="block-3"></span>
      <span class="block-4"></span>
      <span class="block-5"></span>
      <span class="block-6"></span>
      <span class="block-7"></span>
      <span class="block-8"></span>
      <span class="block-9"></span>
      <span class="block-10"></span>
      <span class="block-11"></span>
      <span class="block-12"></span>
      <span class="block-13"></span>
      <span class="block-14"></span>
      <span class="block-15"></span>
      <span class="block-16"></span>
    </div>
  </div>
</div>

<header class="site-header transparent-sidemenu">
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <meta name="viewport" content="initial-scale=1.0, maximum-scale=1" />
  <meta name="title" content="Pasta Labs" />
  <meta
    name="description"
    content="Pasta labs is an indendent web-browser games and tools developer. With offices in Portugal and Poland, we work to make sure our games are based on community feedback."
  />
  <meta
    name="keywords"
    content="vPopulus, games, simplan, rise of society, social, multiplayer, strategy, simulator, politics, virtual, free, entrepreneur, communication, geopolitical, community driven, manage, country, war"
  />
  <meta name="author" content="Pasta Labs" />
  <meta name="publisher" content="Pasta Labs" />
  <meta name="copyright" content="Pasta Labs" />
  <meta
    name="page-type"
    content="website, browser game, browsergame, gamestudio"
  />
  <meta
    name="page-topic"
    content="browser game, strategy game, online game, geopolitical, indie studio"
  />
  <meta name="audience" content="all" />
  <meta name="expires" content="never" />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://pastalabs.eu" />
  <meta property="og:title" content="Pasta labs" />
  <meta property="og:image" content="http://pastalabs.eu/pastalabs_logo.png" />
  <meta
    property="og:description"
    content="The most creative and indie games lab. Pasta labs, because spaghetti is hard to write."
  />
  <meta
    itemprop="name"
    content="Pasta Labs | Making your dream games come true!"
  />
  <meta
    itemprop="description"
    content="Play our games and see you'll get to love all of them."
  />
  <meta itemprop="image" content="http://pastalabs.eu/pastalabs_logo.png" />
  <meta
    name="twitter:description"
    content="The most creative and indie games lab. Pasta labs, because spaghetti is hard to write."
  />
  <meta name="twitter:image" content="http://pastalabs.eu/pastalabs_logo.png" />

  <nav class="navbar navbar-expand-lg bg-white static-nav">
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">
        <img
          src="../../../../assets/pastalabs_logo.png"
          alt="Pasta Labs logo"
        />
      </a>
      <button
        class="navbar-toggler navbar-toggler-right collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#Pastalabs"
      >
        <span> </span>
        <span> </span>
        <span> </span>
      </button>
      <div class="collapse navbar-collapse" id="Pastalabs">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['/']">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/about']">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/projects/vpopulus']"
              >vPopulus</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/partners']">Partners</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/careers']">Careers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/blog']">Blog</a>
          </li>
          <li class="nav-item" *ngIf="isLoggedIn === false">
            <a class="nav-link" [routerLink]="['/login']">Login</a>
          </li>
          <li class="nav-item" *ngIf="isLoggedIn === true">
            <a class="nav-link" [routerLink]="['/account']">Manage</a>
          </li>
          <li class="nav-item" *ngIf="isLoggedIn === true">
            <a class="nav-link" (click)="logout()">Logout</a>
          </li>
        </ul>
      </div>
    </div>

    <a
      href="javascript:void(0)"
      class="d-none d-lg-inline-block sidemenu_btn"
      id="sidemenu_toggle"
    >
      <span></span> <span></span> <span></span>
    </a>
  </nav>

  <div class="side-menu">
    <div class="inner-wrapper">
      <span class="btn-close" id="btn_sideNavClose"><i></i><i></i></span>
      <nav class="side-nav">
        <ul class="navbar-nav w-100">
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['/']">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/about']">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/projects/vpopulus']"
              >vPopulus</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/partners']">Partners</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/careers']">Careers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/blog']">Blog</a>
          </li>
          <li class="nav-item" *ngIf="isLoggedIn === false">
            <a class="nav-link" [routerLink]="['/login']">Login</a>
          </li>
          <li class="nav-item" *ngIf="isLoggedIn === true">
            <a class="nav-link" [routerLink]="['/account']">Manage</a>
          </li>
        </ul>
      </nav>

      <div class="side-footer w-100">
        <ul class="social-icons-simple white top40">
          <li>
            <a href="https://pt-pt.facebook.com/vPopulusGame/"
              ><i class="fa fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/vpopulus_net/"
              ><i class="fa fa-instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/vpopulus"
              ><i class="fa fa-twitter"></i>
            </a>
          </li>
        </ul>
        <p class="whitecolor">
          &copy;2011 - 2020 Pasta Labs. made with
          <i
            _ngcontent-kib-c0=""
            nz-icon=""
            nztheme="outline"
            nztype="heart"
            style="color: red"
            class="anticon anticon-heart"
          >
            <svg
              viewBox="64 64 896 896"
              fill="currentColor"
              width="1em"
              height="1em"
              data-icon="heart"
              aria-hidden="true"
            >
              <path
                d="M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z"
              ></path>
            </svg>
          </i>
          by <a href="">Pasta Labs</a>
        </p>
      </div>
    </div>
  </div>
  <a id="close_side_menu" href="javascript:void(0);"></a>
</header>
 -->
<header class="header-section">
  <div class="header-warp">
    <div class="header-bar-warp d-flex">
  
      <a [routerLink]="['/']" class="site-logo">
        <img style="width: 160px;" src="../../../../assets/img/pastalabs_white.png" alt="Pasta Labs logo" />
      </a>
      <nav class="top-nav-area w-100">
        <div class="user-panel" *ngIf="isLoggedIn === false">
          <a [routerLink]="['/login']">Login</a> / <a [routerLink]="['/register']">Register</a>
        </div>
        <div class="user-panel" *ngIf="isLoggedIn === true">
          <a (click)="logout()">Logout</a>
        </div>

        <ul class="main-menu primary-menu">
          <li><a [routerLink]="['/']">Home</a></li>
          <li><a [routerLink]="['/about']">About</a></li>
          <li><a [routerLink]="['/projects/vpopulus']">vPopulus</a>
          </li>
          <li><a [routerLink]="['/blog']">Blog</a></li>
          <li *ngIf="isLoggedIn === true">
            <a [routerLink]="['/account']">Manage</a>
          </li>
   
        </ul>
      </nav>
    </div>
  </div>
</header>