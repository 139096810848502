<section class="page-top-section set-bg" data-setbg="img/page-top-bg/3.jpg">
    <div class="page-info">
      <h2>Transactions</h2>

    </div>
  </section>
<div id="content">
    <div class="container">
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 call-action call-action-boxed call-action-style1 clearfix">
                    <h3>Transactions</h3>
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Game</th>
                                <th>Method</th>
                                <th>Price</th>
                                <th>Date</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of list">
                                <td>{{ item.projectName }}</td>
                                <td>{{ item.method }}</td>
                                <td>{{ item.amount }}€</td>
                                <td>{{ item.date }}</td>
                                <td>{{ item.description }}</td>
                            </tr>
                            <tr *ngIf="list.length === 0">
                                <td colspan="4">No transactions found.</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Any issue don't hesitate to contact support.</p>
                </div>
            </div>
        </div>
    </div>
</div>