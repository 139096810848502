<!-- <footer id="site-footer" class="padding_half">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 text-center">
        <ul
          class="social-icons bottom25 wow fadeInUp"
          data-wow-delay="300ms"
          style="
            visibility: hidden;
            animation-delay: 300ms;
            animation-name: none;
          "
        >
          <li>
            <a href="https://pt-pt.facebook.com/vPopulusGame/"
              ><i class="fa fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/vpopulus"
              ><i class="fa fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/vpopulus_net/"
              ><i class="fa fa-instagram"></i>
            </a>
          </li>
        </ul>
        <p
          class="copyrights wow fadeInUp"
          data-wow-delay="400ms"
          style="
            visibility: hidden;
            animation-delay: 400ms;
            animation-name: none;
          "
        >
          © 2011 - 2020 Pasta Labs. made with
          <i class="fa fa-heart pulse"></i> by
          <img src="../../../../assets/pastalabs_logo.png" height="20px" />
        </p>
      </div>
    </div>
  </div>
</footer>
 -->
<footer class="footer-section">
  <div class="container">
    <div class="footer-left-pic">
      <!-- <img src="./assets/img/footer-left-pic.png" alt=""> -->
    </div>
    <div class="footer-right-pic">
    <!--   <img src="./assets/img/footer-right-pic.png" alt=""> -->
    </div>
    <a [routerLink]="['/']" class="footer-logo">
      <img style="width: 160px;" src="../../../../assets/img/pastalabs_white.png" alt="Pasta Labs logo" />

    </a>
    <ul class="main-menu footer-menu">
      <li><a [routerLink]="['/']">Home</a></li>
      <li><a [routerLink]="['/about']">About</a></li>
      <li><a [routerLink]="['/projects/vpopulus']">vPopulus</a>
      <li><a [routerLink]="['/blog']">Blog</a></li>
      <!--       <li><a href="">News</a></li>
      <li><a href="">Contact</a></li> -->
    </ul>
    <div class="footer-social d-flex justify-content-center">
      <!--       <a href="#"><i class="fa fa-pinterest"></i></a> -->
      <a href="https://pt-pt.facebook.com/vPopulusGame/" target="_blank"><i class="fa fa-facebook"></i></a>
      <a href="https://x.com/vpopulus" target="_blank"><i class="fa fa-twitter"></i></a>
      <a href="https://www.instagram.com/vpopulus_net/" target="_blank"><i class="fa fa-instagram"></i></a>
      <!--       <a href="#"><i class="fa fa-dribbble"></i></a>
      <a href="#"><i class="fa fa-behance"></i></a> -->
    </div>
    <div class="copyright"><a href="">Pasta Labs</a> 2024 &#64; All rights reserved</div>
  </div>
</footer>