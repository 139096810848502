<!-- <div class="container">
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <h5 class="card-title text-center">Login</h5>
          <form [formGroup]="form" (submit)="login()">
            <div class="form-label-group">
              <input
                type="email"
                formControlName="email"
                id="inputEmail"
                class="form-control"
                placeholder="Email address"
                required
                autofocus
              />
              <label for="inputEmail">Email address</label>
            </div>

            <div class="form-label-group">
              <input
                type="password"
                formControlName="password"
                id="inputPassword"
                class="form-control"
                placeholder="Password"
                required
              />
              <label for="inputPassword">Password</label>
            </div>

            <button
              class="btn btn-lg btn-primary bgpasta btn-block text-uppercase"
              type="submit"
            >
              Login
            </button>
          </form>
          <hr />
          <p>
            You can register
            <a [routerLink]="['/register']">Pasta labs Account</a>.
          </p>
          <p>
            <a [routerLink]="['/password/recover']">Forgot your password?</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
 -->
<section class="page-top-section set-bg">
  <div class="page-info">
    <h2>Login</h2>
  </div>
</section>
<section class="contact-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 order-2 order-lg-1"></div>
      <div class="col-lg-6 order-2 order-lg-1">
        <h2 style="color: white;">Login</h2>
        <form [formGroup]="form" class="contact-form" (submit)="login()">
          <input type="email" formControlName="email" id="inputEmail" placeholder="Email address" required autofocus>
          <input  type="password"
          formControlName="password"
          id="inputPassword"   placeholder="Password"
          required>
        <div style="text-align: center;">
        <p>
          <a [routerLink]="['/password/recover']">Forgot your password?</a>
        </p></div>
          <button type="submit" class="site-btn"> Login<img src="./assets/img/icons/double-arrow.png" alt="#" /></button>
        </form>
        <br>
        <hr>
        <div style="text-align: center;">
        <p>
          Don't have an account yet?
          <a [routerLink]="['/register']">Register here</a>.
        </p>
        </div>
      </div>

    </div>
  </div>
</section>