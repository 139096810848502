import { Component, OnInit } from '@angular/core';
import { GenericHttpService } from 'src/app/generic-http-service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.less'],
})
export class ManageComponent implements OnInit {
  list = [];

  constructor(private httpService: GenericHttpService) {}

  ngOnInit(): void {
    this.httpService.get(`/api/accounts/transactions`).subscribe((r) => {
      this.list = r;
    });
  }
}
