<!-- <section id="contactus" class="padding_top">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="heading-title heading_space wow fadeInUp" data-wow-delay="300ms">
                    <span>Game</span>
                    <h2 class="darkcolor">vPopulus</h2>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 margin_bottom wow fadeInUp" data-wow-delay="350ms">
                <p> vPopulus is a social, strategic, massively multiplayer online browser game. It's a real-time model
                    of the world where players can join political, economic, military and journalistic careers. Players
                    or citzens can manage companies and sell products both on national and international markets,
                    exchange different currencies for personal profit, become a stockmarket mogul, join political
                    parties and candidate for congress or president. You can join the army and fight in a war against
                    another country or you can be journalist and become a world known presence. vPopulus is a game that
                    is heavily based on the social aspects of gaming. It’s not just a game, it’s a huge community of
                    players from around the globe. The possibilities are endless! </p>
                <div class="row">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-sm-12 text-center">
                                <span style="color: black;">Share vPopulus!</span>
                                <ul class="social-icons bottom25 wow fadeInUp" data-wow-delay="300ms"
                                    style="visibility: hidden; animation-delay: 300ms; animation-name: none;">
                                    <li><a href="https://www.facebook.com/vPopulusGame/"><i class="fa fa-facebook"></i>
                                        </a> </li>
                                    <li><a href="https://twitter.com/vpopulus"><i class="fa fa-twitter"></i> </a> </li>
                                    <li><a href="https://www.instagram.com/vpopulus_net/"><i
                                                class="fa fa-instagram"></i> </a> </li>
                                    <li><a href="https://wiki.vpopulus.net"><i class="fa fa-wikipedia-w"></i> </a> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 margin_bottom">
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="d-block w-100" src="../../../../assets/images/1ss.png" alt="First slide">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../../../assets/images/2ss.png" alt="second slide">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../../../assets/images/3ss.png" alt="third slide">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../../../assets/images/4ss.png" alt="fourth slide">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../../../assets/images/5ss.png" alt="fifth slide">
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="games-single-page">
    <div class="container">
        <div class="game-single-preview">
            <img src="./assets/img/games/vpopulus.png" alt="">
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 game-single-content">
                <h2 class="gs-title">vPopulus</h2>
                <p>vPopulus is a social, strategic, massively multiplayer online browser game. It's a real-time model of
                    the world where players can join political, economic, military and journalistic careers. Players or
                    citzens can manage companies and sell products both on national and international markets, exchange
                    different currencies for personal profit, become a stockmarket mogul, join political parties and
                    candidate for congress or president. You can join the army and fight in a war against another
                    country or you can be journalist and become a world known presence. vPopulus is a game that is
                    heavily based on the social aspects of gaming. It’s not just a game, it’s a huge community of
                    players from around the globe. The possibilities are endless!</p>
                <div class="geme-social-share pt-5 d-flex">
                    <p>Share:</p>
<!--                     <a href="#"><i class="fa fa-pinterest"></i></a> -->
                    <a href="#"><i class="fa fa-facebook"></i></a>
                    <a href="#"><i class="fa fa-twitter"></i></a>
                   <a href="#"><i class="fa fa-instagram"></i></a>
                    <!-- <a href="#"><i class="fa fa-behance"></i></a>  -->
                </div>
            </div>
        <!--     <div class="col-xl-3 col-lg-4 col-md-5 sidebar game-page-sideber">
                <div id="stickySidebar">
                    <div class="widget-item">
                        <div class="rating-widget">
                            <h4 class="widget-title">Ratings</h4>
                            <ul>
                                <li>Price<span>3.5/5</span></li>
                                <li>Graphics<span>4.5/5</span></li>
                                <li>Levels<span>3.5/5</span></li>
                                <li>Levels<span>4.5/5</span></li>
                                <li>Dificulty<span>4.5/5</span></li>
                            </ul>
                            <div class="rating">
                                <h5><i>Rating</i><span>4.5</span> / 5</h5>
                            </div>
                        </div>
                    </div>
                    <div class="widget-item">
                        <div class="testimonials-widget">
                            <h4 class="widget-title">Testimonials</h4>
                            <div class="testim-text">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolo re magna aliqua. Quis ipsum suspend isse ultrices.</p>
                                <h6><span>James Smith,</span>Gamer</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- Games end-->

<!-- <section class="game-author-section">
    <div class="container">
        <div class="game-author-pic set-bg" data-setbg="img/author.jpg"></div>
        <div class="game-author-info">
            <h4>Written by: Michael Williams</h4>
            <p>Vivamus volutpat nibh ac sollicitudin imperdiet. Donec scelerisque lorem sodales odio ultricies, nec
                rhoncus ex lobortis. Vivamus tincid-unt sit amet sem id varius. Donec elementum aliquet tortor.
                Curabitur justo mi, efficitur sed eros alique.</p>
        </div>
    </div>
</section> -->


<!-- Newsletter section -->
<!-- <section class="newsletter-section">
    <div class="container">
        <h2>Subscribe to our newsletter</h2>
        <form class="newsletter-form">
            <input type="text" placeholder="ENTER YOUR E-MAIL">
            <button class="site-btn">subscribe <img src="img/icons/double-arrow.png" alt="#" /></button>
        </form>
    </div>
</section> -->
<!-- Newsletter section end -->