import { Component, OnInit } from '@angular/core';
import { GenericHttpService } from 'src/app/generic-http-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.less'],
})
export class BlogPostComponent implements OnInit {
  token;

  news;
  comments;

  latestNews = [];

  constructor(
    private httpService: GenericHttpService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.token = localStorage.getItem(`token`);

    this.route.params.subscribe((params) => {
      this.httpService
        .get(`/api/blog/news/${params['title']}`)
        .subscribe((news) => {
          this.news = news;
          console.log('desc', this.news)
          news.createdOn = news.createdOn.split('.')[0].replace('T', ' ');
          this.httpService
            .get(`/api/blog/news/${news.id}/tags`)
            .subscribe((tags) => {
              news['tags'] = tags;
            });
        });
    });

    this.httpService
      .get(`/api/blog/news/active?page=1&pageSize=3`)
      .subscribe((r) => {
        this.latestNews = r.list;
        this.latestNews.forEach((news) => {
          news.titleUrl = encodeURI(news.title);
          news.createdOn = news.createdOn.split('.')[0].replace('T', ' ');
        });
      });
  }
  generateImageIndex(id: any): number {
    const stringId = JSON.stringify(id);
    let hash = 0;
    for (let i = 0; i < stringId.length; i++) {
      hash = stringId.charCodeAt(i) + ((hash << 5) - hash);
    }
    return (Math.abs(hash) % 5) + 1;
  }
}
