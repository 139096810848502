<section class="page-header parallaxie padding_top center-block" style="background-image: url('/images/partners.jpg'); background-size: cover; background-repeat: no-repeat; background-attachment: fixed; background-position: center 0px;">
    <div class="container">
       <div class="row">
          <div class="col-sm-12">
             <div class="page-titles text-center">
                <h2 class="whitecolor defaultcolor bottom30">Become a Partner</h2>
             </div>
          </div>
       </div>
    </div>
 </section>
 <section id="our-process" class="padding bglight">
    <div class="container">
 <div class="row">
 <div class="col-md-6 col-sm-12">               
 <h2 class="darkcolor bottom25 wow fadeIn" data-wow-delay="350ms" style="visibility: visible; animation-delay: 350ms; animation-name: fadeIn;">Why us</h2>
     <p class="bottom30 wow fadeInUp" data-wow-delay="400ms" style="visibility: visible; animation-delay: 400ms; animation-name: fadeInUp;">We've started this amazing journey 10 years ago, we did it by ourselves, but you don't have to do it alone.</p>
     <p class="bottom30 wow fadeInUp" data-wow-delay="400ms" style="visibility: visible; animation-delay: 400ms; animation-name: fadeInUp;">If you're doing a game or project and need some guidance, together, we can integrate your project in Pasta Labs while you still maintain ownership. You can use all our tools and we'll share the knowledge we obtained over the past 10 years.</p>
     <a href="mailto:partners@pastalabs.eu" class="button btnprimary top25">Become a Pasta Brother </a>
 
 </div>
 <div class="col-md-6 col-sm-12">
             <div class="price-table top60">
                <h3 class="bottom20 darkcolor">See what we can offer</h3>
                <ul class="top20">
                   <li><span>Pre-made Wiki</span></li>
                   <li><span>Optimized translation tool (I18N)</span></li>
                   <li><span>Pre-made blog like template</span></li>
                   <li><span>Project management tools</span></li>
                   <li><span>Access to our server infrastructure</span></li>
                   <li><span>Optimized plataform to marketing and transactional emails</span></li>
                   <li><span>Access to our team</span></li>
                   <li class="not-support"><span>Free programing services</span></li>
                </ul>
                <div class="clearfix"></div>
             </div>
          </div>
 </div>
    </div>
 </section>