<!-- 
    <section id="our-blog" class="padding bglight">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <div class="news_item shadow">
              <div class="news_desc text-left">
                <h3 class="text-capitalize font-light darkcolor">
                  <a href="blog-detail.html">{{ news.title }}</a>
                </h3>
                <ul class="meta-tags top20 bottom20">
                  <li>
                    <i class="fa fa-calendar"></i>
                    {{ news.createdOn }}
                  </li>
                </ul>
                <span [innerHtml]="news.description"></span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <aside class="sidebar whitebox">
              <div class="widget heading_space">
                <h4 class="text-capitalize darkcolor bottom20">Recent Posts</h4>

                <div
                  class="single_post bottom15"
                  *ngFor="let item of latestNews"
                >
                  <a href="#" class="post"
                    ><img
                      src="https://cdn3.iconfinder.com/data/icons/lineo-social/100/news-512.png"
                      alt="post image"
                  /></a>
                  <div class="text">
                    <a [routerLink]="['/blog/news', item.titleUrl]">{{
                      item.title
                    }}</a>
                    <span>{{ item.createdOn }}</span>
                  </div>
                </div>
              </div>

              <div class="widget heading_space">
                <h4 class="text-capitalize darkcolor bottom20">Tags</h4>
                <ul class="webtags">
                  <li *ngFor="let tag of news.tags">
                    <a [routerLink]="['/blog/tags', tag]">{{ tag }}</a>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
 -->
<section class="page-top-section set-bg" data-setbg="img/page-top-bg/3.jpg">
  <div class="page-info">
    <h2>{{ news.title }}</h2>
    <div class="site-breadcrumb">
      {{ news.createdOn }}
    </div>
  </div>
</section>
<section class="blog-page">
  <div class="container">
    <div class="row">
      <div class="col-xl-9 col-lg-8 col-md-7">
        <span class="text" [innerHtml]="news.description"></span>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-5 sidebar">
        <div id="stickySidebar">
          <div class="widget-item">
            <h4 class="widget-title">Recent Posts</h4>
            <div class="trending-widget">
              <div class="tw-item" *ngFor="let item of latestNews">
                <div class="tw-thumb">
                  <img style="width: 80px;" [src]="item.imageUrl || ('./assets/img/blog/' + generateImageIndex(item.id) + '.jpg')" alt="Imagem da notícia">
                </div>
                <div class="tw-text">
                  <div class="tw-meta">{{ item.createdOn }}</div>
                  <h5 style="cursor: pointer;" [routerLink]="['/blog/news/', item.title]" >{{ item.title }}</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="widget-item">
            <div class="categories-widget">
              <h4 class="widget-title">Tags</h4>
              <ul>
                <li *ngFor="let tag of news.tags">
                  <a [routerLink]="['/blog/tags', tag]">{{ tag }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>