import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-red-panda',
  templateUrl: './red-panda.component.html',
  styleUrls: ['./red-panda.component.less']
})
export class RedPandaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
