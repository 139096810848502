<!-- <div class="container">
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <nz-spin [nzSpinning]="isLoading">
            <h5 class="card-title text-center">
              <p>
                You can reset your password here. <br /><small
                  >You will receive a link on your email that allows you to
                  choose a new password</small
                >
              </p>
            </h5>
            <div class="form-label-group">
              <input
                id="emailInput"
                name="email"
                [(ngModel)]="email"
                placeholder="Email address"
                class="form-control"
                required
                type="email"
              />
              <label for="emailInput">Email</label>
            </div>

            <button
              class="btn btn-lg btn-primary btn-block text-uppercase"
              type="button"
              (click)="request()"
            >
              Send recover link now
            </button>
          </nz-spin>
        </div>
      </div>
    </div>
  </div>
</div> -->

<section class="page-top-section set-bg">
  <div class="page-info">
    <h2>Recover password</h2>
  </div>
</section>
<section class="contact-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 order-2 order-lg-1"></div>
      <div class="col-lg-6 order-2 order-lg-1">
        <h2 style="color: white; text-align: center;">You can reset your password here.</h2>
        <nz-spin [nzSpinning]="isLoading">
          <h5 class="card-title text-center">
            <p>
             <small style="color: white;"
                >You will receive a link on your email that allows you to
                choose a new password</small
              >
            </p>
          </h5>
          <div class="contact-form">
            <input
              id="emailInput"
              name="email"
              [(ngModel)]="email"
              placeholder="Email address"
              required
              type="email"
            />
          </div>

          <button
          class="site-btn"            type="button"
            (click)="request()"
          >
            Send recover link now
          </button>
        </nz-spin>
      </div>

    </div>
  </div>
</section>