import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GenericHttpService } from 'src/app/generic-http-service';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.less'],
})
export class ArticleListComponent implements OnInit {
  isLoading = true;

  news = { list: [], totalEntries: 0 };

  page = 1;

  constructor(
    private genericHttpService: GenericHttpService,
    private messageService: NzMessageService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.genericHttpService
      .get(`/api/blog/news?page=${this.page}&pageSize=100`)
      .subscribe((r) => {
        this.news = r;
        this.news.list.forEach((item) => {
          item.titleUrl = encodeURI(item.title);
          item.createdOn = item.createdOn.split('.')[0].replace('T', ' ');
        });
        this.isLoading = false;
      });
  }

  unpublish(id) {
    this.isLoading = true;
    this.genericHttpService.delete(`/api/blog/news/${id}`).subscribe((r) => {
      this.messageService.success('Article unpublished!');
      this.loadData();
      this.isLoading = false;
    });
  }
}
