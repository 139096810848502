import { Component, OnInit } from '@angular/core';
import { GenericHttpService } from 'src/app/generic-http-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
})
export class HomeComponent implements OnInit {
  isLoading = false;

  news = [];
  trendingNews = [];

  constructor(private httpService: GenericHttpService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.httpService.get('/api/blog/news/active?page=1&pageSize=3').subscribe(r => {
      this.news = r.list;
      this.news.forEach(newsItem => {
        newsItem.titleUrl = encodeURI(newsItem.title);
      });
      this.isLoading = false;
    });

    this.httpService.get('/api/blog/news/trending?page=1&pageSize=4').subscribe(r => {
      this.trendingNews = r.list;
    });
    this.initializeCountdown('October 15, 2024 00:00:00');

  }
  generateImageIndex(id: any): number {
    const stringId = JSON.stringify(id);
    let hash = 0;
    for (let i = 0; i < stringId.length; i++) {
      hash = stringId.charCodeAt(i) + ((hash << 5) - hash);
    }
    return (Math.abs(hash) % 5) + 1;
  }
  initializeCountdown(targetDate: string): void {
    const countdownElement = document.getElementById('countdown');
    const targetTime = new Date(targetDate).getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = targetTime - now;

      if (distance < 0) {
        countdownElement.innerHTML = "Event Started!";
        clearInterval(interval);
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      countdownElement.innerHTML = `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    const interval = setInterval(updateCountdown, 1000);
    updateCountdown(); 
  }
}
